Vue.component('resetPasswordComponent',{
    data:function(){
        return {
            personMail:'',
            validateMail:null,
            message:null
        };
    },
    /*watch:{
        'filter': function () {
            if (!this.filter.length)
                this.readyToSend = false;
        },
    },*/
    methods:{
        validatorMail(){
            var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            this.validateMail=re.test(this.personMail);
            if(!this.validateMail)
                this.message="Dirección de Mail incorrecta";
            else
                this.message=null;
            //console.log(this.message,this.validateMail)
        },
        clear(){
            this.personMail='';
            this.validateMail=null;
            this.message=null;
        },
        sendMail:function(){
            axios.post('/ecommerce/api/sendLostPassword',{
                personMail:this.personMail
            }).then((response)=>{
                console.log(response.data);
                if(response.data.ok) {
                    this.validateMail=true;
                    this.message = 'Se ha enviado la informacíón a su casilla de mail';
                }
                else {
                    this.validateMail=false;
                    this.message = response.data.error
                }

            });

        },
        closeModal(){
            this.clear();
            $(this.$refs['resetPassword']).modal('close');
        }
    },
    template:`<div ref="resetPassword" id="resetModal" class="modal">
        <div class="modal-content">
            <h5 class="center-align">{{tr('Send Password Recovery Mail')}}</h5>
            <div class="col s6 offset-s3 center-align">
                <div class="input-field">
                    <input :placeholder="tr('Your email')" v-model="personMail" name="personMail"  @change="validatorMail" type="email" class="validate">
                </div>
                <p class="input-field">
                    <input name="redirect_on_success" class="input" type="hidden" value="/ecommerce/#/">
                </p>
                <template v-if="message">
                    <div :class="{'center-align':true,'red':validateMail==false,'green':validateMail==true,'white-text':true}">
                        <p>{{message}}</p>
                    </div>
                </template>
                <p class="control center-align">
                    <button :class="{'center-align':true, btn:true, 'disabled':validateMail==false}" @click.prevent="sendMail">{{tr('Accept')}}</button>
                    <button class="modal-action modal-close center-align btn" @click.prevent="closeModal">{{tr('Close')}}</button>
                </p>
            </div>
        </div>
    </div>`
});